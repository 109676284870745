<template>
  <div class="custom-select" :tabindex="tabindex">
    <div
      class="selected flex justify-between"
      :class="{ open: open }"
      @click="well_disabled ? null : (open = !open)"
    >
      <span class="flex" :title="selected">
        {{ getDemoWell ? getDemoWell : selected }}
      </span>
      <span v-if="!well_disabled" class="well_drop_icon">
        <!-- <i class="fas fa-angle-down " ></i> -->
        <svg
          class="mt-1"
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="5"
          viewBox="0 0 12 8"
          fill="none"
        >
          <path
            d="M10.9999 2.59L6.70995 6.83C6.61699 6.92372 6.50638 6.99812 6.38453 7.04889C6.26267 7.09965 6.13196 7.12579 5.99995 7.12579C5.86794 7.12579 5.73723 7.09965 5.61537 7.04889C5.49351 6.99812 5.38291 6.92372 5.28995 6.83L1.04995 2.59C0.95622 2.49703 0.881826 2.38643 0.831057 2.26457C0.780288 2.14271 0.75415 2.01201 0.75415 1.88C0.75415 1.74798 0.780288 1.61728 0.831057 1.49542C0.881826 1.37356 0.95622 1.26296 1.04995 1.17C1.23731 0.983744 1.49076 0.879203 1.75495 0.879203C2.01913 0.879203 2.27259 0.983744 2.45995 1.17L5.99995 4.71L9.53995 1.17C9.72621 0.985251 9.97761 0.8811 10.2399 0.879995C10.3716 0.879234 10.502 0.904459 10.6239 0.954224C10.7457 1.00399 10.8565 1.07731 10.9499 1.17C11.047 1.25961 11.1254 1.36758 11.1805 1.48765C11.2356 1.60773 11.2664 1.73754 11.271 1.86957C11.2757 2.00161 11.2541 2.13325 11.2076 2.25691C11.161 2.38056 11.0905 2.49377 10.9999 2.59Z"
            fill="#727272"
          />
        </svg>
        <!-- <i class="fas fa-angle-down " v-if="!open"></i> -->
        <!-- <i class="fas fa-angle-up " v-else></i> -->
      </span>
    </div>
    <!-- :class="{ selectHide: !open }" -->
    <div class="items" v-if="open">
      <div
        v-for="(option, i) of optionList"
        :key="i"
        @click="optionClicked(option)"
      >
        {{ option[this.displayField] }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: [String, Number],
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    well_disabled: {
      type: Boolean,
      default: false,
    },
    displayField: {
      type: String,
      default: "name",
      required: false,
    },
    valueField: {
      type: String,
      default: "value",
      required: false,
    },
    displayId: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0][this.valueField]
        : null,
      open: false,
      rigname: "",
      optionList: [],
      autoCompleteWatcher:null
    };
  },
  methods: {
    optionClicked(option) {
      this.selected = option[this.displayField];
      this.open = false;
      this.$store.dispatch("disp/setSelectedWellDisplayname", {
        id: this.displayId,
        name: option[this.displayField],
      });
      this.$emit("input", option[this.valueField]);
    },
    getRenamed(originalName){
      if(originalName=="Haddad Unit No.1H25"){
        return "Demo-25-TIGHT PULL";
      }else if(originalName=="Haddad Unit No.1H23"){
        return "Demo-23-TIH-HELD UP";
      }else if(originalName=="Haddad Unit No.1H22"){
        return "Demo-22-TOOH-TIGHT PULL";
      }else if(originalName=="Demo0112"){
        return "Demo-12-STACKING WEIGHT";
      }else if(originalName=="TY RANCH WEST 1LS 1LS13"){
        return "Demo-13-PRESSURE SPIKE";
      }else if(originalName=="TY RANCH WEST 1LS 1LS14"){
        return "Demo-14-MOTOR STALL PROBABLE";
      }else if(originalName=="Demo0115"){
        return "Demo-15-ERRATIC MSE";
      }else if(originalName=="Demo0116"){
        return "Demo-16-ERRATIC ROP";
      }else if(originalName=="Demo0117"){
        return "Demo-17-WASHOUT PROBABLE";
      }else if(originalName=="Demo2wb18"){
        return "Demo-18-STICKSLIP LIKELY";
      }else if(originalName=="Demo2wb19"){
        return "Demo-19-STICKSLIP PROBABLE";
      }else if(originalName=="Demo0120"){
        return "Demo-20-DIFF STICK";
      }
      else if(originalName=="Demo0121"){
        return "Demo-21-STALL OUT PROBABLE";
      }else if(originalName=="TY RANCH WEST 1LS 1LS9"){
        return "Demo-9-Swab & Surge(RIH)";
      }else if(originalName=="TY RANCH WEST 1LS 1LS8"){
        return "Demo-8-Swab & Surge(POOH)";
      }else if(originalName=="TY RANCH WEST 1LS 1LS6"){
        return "Demo-6-Kill sheet-Horizontal";
      }else if(originalName=="TY RANCH WEST 1LS 1LS5"){
        return "Demo-5-Kill sheet-Vertical";
      }else if(originalName=="TY RANCH WEST 1LS 1LS4"){
        return "Demo-4-Hydraulics";
      }else if(originalName=="TY RANCH WEST 1LS 1LS3"){
        return "Demo-3-General Drilling";
      }else if(originalName=="TY RANCH WEST 1LS 1LS2"){
        return "Demo-2-Dis";
      }else if(originalName=="TY RANCH WEST 1LS 1LS10"){
        return "Demo-10-Torque and Drag";
      }else if(originalName=="LWDdemowellbore"){
        return "Demo-7-LWD";
      }else if(originalName=="TY RANCH WEST 1LS 1LS111"){
        return "Demo-11-1";
      }else if(originalName=="TY RANCH WEST 2JM112"){
        return "Demo-11-2";
      }else if(originalName=="T.P. Smith HV Unit A #1H113"){
        return "Demo-11-3";
      }else if(originalName=="TY RANCH WEST 1LS 1LS1"){
        return "Demo-1-Directional";
      }else{
        return originalName.charAt(0)+'-demo';
      }
    },
    mapBasedOnDraggedScreen(currentList){
      try {
        let currentScreenRect = this.$store.state.disp.rects.find((each)=> each.displayId == this.displayId)
        let cuurScreen=this.$store.state.disp.rects[0].savedName;
        if(currentScreenRect){
          cuurScreen = currentScreenRect.savedName;
        }
        if(cuurScreen=="DRILLING INTERVENTION SYSTEM"){
          return this.sortWellList(currentList.filter(w=>{
            if(w.name=="Demo-2-Dis" || w.name=="Demo-12-STACKING WEIGHT" || w.name=="Demo-13-PRESSURE SPIKE" || w.name=="Demo-14-MOTOR STALL PROBABLE" || w.name=="Demo-15-ERRATIC MSE" || w.name=="Demo-16-ERRATIC ROP" || w.name=="Demo-17-WASHOUT PROBABLE" || w.name=="Demo-18-STICKSLIP LIKELY" || w.name=="Demo-19-STICKSLIP PROBABLE" || w.name=="Demo-20-DIFF STICK" || w.name=="Demo-21-STALL OUT PROBABLE" || w.name=="Demo-22-TOOH-TIGHT PULL" || w.name=="Demo-23-TIH-HELD UP" || w.name=="Demo-25-TIGHT PULL"){
              return w
            }
          }));
        }else if(cuurScreen=="GENERAL DRILLING"){
          return currentList.filter(w=>{
            if(w.name=="Demo-3-General Drilling" ){
              return w
            }
          });
        }else if(cuurScreen=="HYDRAULICS"){
          return currentList.filter(w=>{
            if(w.name=="Demo-4-Hydraulics" ){
              return w
            }
          });
        }else if(cuurScreen=="KILL SHEET"){
          return this.sortWellList(currentList.filter(w=>{
            if(w.name=="Demo-5-Kill sheet-Vertical" || w.name=="Demo-6-Kill sheet-Horizontal"){
              return w
            }
          }));
        }else if(cuurScreen=="SWAB AND SURGE"){
          return this.sortWellList(currentList.filter(w=>{
            if(w.name=="Demo-8-Swab & Surge(POOH)" || w.name=="Demo-9-Swab & Surge(RIH)"){
              return w
            }
          }));
        }else if(cuurScreen=="TORQUE AND DRAG"){
          return currentList.filter(w=>{
            if(w.name=="Demo-10-Torque and Drag"){
              return w
            }
          });
        }else if(cuurScreen=="LWD"){
          return currentList.filter(w=>{
            if(w.name=="Demo-7-LWD"){
              return w
            }
          });
        }else if(cuurScreen=="WELL CORRELATION"){
          return currentList.filter(w=>{
            if(w.name=="Demo-11-1" || w.name=="Demo-11-2" || w.name=="Demo-11-3"){
              return w
            }
          });
        }else if(cuurScreen=="DIRECTIONAL"){
          return currentList.filter(w=>{
            if(w.name=="Demo-1-Directional"){
              return w
            }
          });
        }
        else{

          return currentList
        }
      } catch (error) {
        return currentList
      }
      

    },
    sortWellList(unsorted){
      return unsorted.sort((a, b) => {
        const numA = parseInt(a.name.match(/\d+/)[0]);  // Extract the number from 'name'
        const numB = parseInt(b.name.match(/\d+/)[0]);  // Extract the number from 'name'
        return numA - numB;
      })
    }

  },
  mounted() {
    this.optionList=this.mapBasedOnDraggedScreen(this.options.map((i)=>{return {name: this.getRenamed(i.name),value:i.value}}));
    
   
    if (this.selected && this.selected!="Well") {
      this.$emit("input", this.selected);
    }
    this.autoCompleteWatcher = this.subNavBarWatcher = this.$store.subscribe(
        (mutation, state) => {
          switch (mutation.type) {
            case "data/setCustomer":
            if(this.optionList.length>0){
                    setTimeout(() => {
                      this.$store.dispatch("disp/setSelectedWellDisplayname", {
                        id: this.displayId,
                        name: this.optionList[0].name,
                      });
                      this.$emit('set_defaultWel',this.optionList[0].value)
                    }, 500);
                    
                  }
                        
            default:
              break;
          }
        })
  },
  watch: {
    default(newValue) {
      const nameAr = newValue.split(" - ");

      if (nameAr.length > 0) {
        const found = this.optionList.find((e) => e.value == nameAr[0]);
        if (found) {
          this.$store.dispatch("disp/setSelectedWellDisplayname", {
            id: this.displayId,
            name: found.name,
          });
        }
      }

      this.selected = newValue;
    },
    options(newValue) {
      this.optionList = this.mapBasedOnDraggedScreen(newValue.map((i) => {
        return { name: this.getRenamed(i.name), value: i.value };
      }));
     
    },
    
  },
  computed: {
    getDemoWell() {
      return (
        this.$store.state.disp.selecteDemoWellName &&
        this.$store.state.disp.selecteDemoWellName[this.displayId]
      );
    }
  },
  beforeUnmount() {
  if (this.autoCompleteWatcher)this.subNavBarWatcher();
  },
};
</script>

<style scoped>
.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  /* height: 47px; */
  /* line-height: 47px; */
  font-size: 10px;
  font-weight: 600;
  /* border-right: 0.5px solid #1b1a1f */
}

.custom-select .selected {
  /* background-color: #0a0a0a; */
  /* border-radius: 6px; */
  /* border: 1px solid #666666; */
  color: var(--colorBoxWhiteNum);
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  font-size: 9.5px;
  font-weight: 400;
  display: flex !important;
  height: 25px;
  align-self: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.trend_props.custom-select .selected {
  color: var(--colorBoxHeadingText);
}

.custom-select .selected.open {
  border: 1px solid var(--activeTextColor);
  /* border-radius: 6px 6px 0px 0px; */
  height: 25px;
  padding: 2px;
}

.custom-select .selected:after {
  position: absolute;
  /* content: ""; */
  top: 22px;
  right: 3em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.custom-select .items {
  color: #fff;
  /* border-radius: 0px 0px 6px 6px; */
  overflow-x: hidden;
  overflow-y: scroll;
  /* scrollbar-width: thin; */
  /* border-right: 1px solid #5aba81;
  border-left: 1px solid #5aba81;
  border-bottom: 1px solid #5aba81; */
  border-right: 1px solid var(--activeTextColor);
  border-left: 1px solid var(--activeTextColor);
  border-bottom: 1px solid var(--activeTextColor);
  /* position: absolute; */
  background-color: var(--root_nav);
  left: 0;
  right: 0;
  z-index: 1;
  font-size: 9.5px;
  top: 24px;
  font-weight: 400;
  max-height: 24rem;
}

.custom-select .items div {
  color: var(--colorBoxWhiteNum);
  padding: 3px 2em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  /* background-color: #5aba81; */
  background-color: var(--activeTextColor);
  color: #fff;
  font-weight: 600;
}

.selectHide {
  display: none;
}
.correlation_bar.custom-select .selected {
  display: flex;
  justify-content: space-between;
}
.fromDIS .items {
  position: absolute;
}
</style>
